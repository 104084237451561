var $dialogEl: JQuery;

function showKendoErrorDialog(title: string, message: string | boolean, autoClose?: boolean) {
    createDialogDiv();
    var content =
        '<div class="modal-body" id="modalStatusMessage" style="text-decoration: bold">' +
            message +
            '      </div>';

    $dialogEl.kendoDialog({
        width: "450px",
        title: title,
        closable: true,
        modal: true,
        content: content
    });

    var isAutoClose = (autoClose != null && autoClose != false);

    if (isAutoClose) {
        setTimeout(
            function () {
                this.$dialogEl.data("kendoDialog").close();
            }, 2000);
    }
}

function createDialogDiv() {
    var dialogEl = document.createElement('div');
    dialogEl.setAttribute("id", "errorDialog");
    document.body.append(dialogEl);
    this.$dialogEl = $(dialogEl);
}